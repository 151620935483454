import { CheckoutQuery as SourceCheckoutQuery } from 'AdvoxQuery/Checkout.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

import ElasticSuiteQuery from './ElasticSuite.query';

/** @namespace Pwa/Query/Checkout/Query */
export class CheckoutQuery extends SourceCheckoutQuery {
    getPlaceOrderMutation(
        guestCartId,
        customerComment,
        newsletterSubscription,
        przelewy24PaymentMethodId,
        przelewy24RegulationAccept
    ) {
        const mutation = new Field('s_placeOrder')
            .setAlias('placeOrder')
            .addArgument('customerComment', 'String', customerComment)
            .addArgument('subscribeToNewsletter', 'Int', +newsletterSubscription)
            .addArgument('przelewy24_payment_method_id', 'Int', +przelewy24PaymentMethodId)
            .addArgument('przelewy24_regulation_accept', 'Boolean', przelewy24RegulationAccept)
            .addField(this._getOrderData())
            .addField(ElasticSuiteQuery.getTrackerVariablesField());

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', guestCartId);
        }

        return mutation;
    }

    _getEstimatedShippingFields() {
        return [...super._getEstimatedShippingFields(), 'disabled_from_large_size_delivery'];
    }

    _getOrderProducts() {
        return new Field('order_products').addFieldList([
            // 'delivery_time',
            this._getProductImage(),
            'name',
            'original_price',
            'quantity',
            'qty',
            'special_price',
            'unit_price',
            'row_total',
            'id',
            'sku',
            this._getCategoriesField(),
            this._getStockItemField(),
        ]);
    }

    _getCategoriesField() {
        return new Field('categories').addFieldList(['name']);
    }

    _getStockItemField() {
        return new Field('stock_item').addFieldList(this._getStockItemFields());
    }

    _getStockItemFields() {
        return ['in_stock'];
    }
}

export default new CheckoutQuery();
