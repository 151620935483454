import myAccountAddressForm from 'Component/MyAccountAddressForm/MyAccountAddressForm.form';
import { BILLING_STEP, SHIPPING_STEP } from 'Route/Checkout/Checkout.config';

/** @namespace Pwa/Component/CheckoutAddressForm/Form/checkoutAccountAddressForm */
export const checkoutAccountAddressForm = (props, events, step) => {
    const { onAddressChange, onVatIdBlur } = events || {};

    const fieldMap = myAccountAddressForm(
        { ...props, showVatNumber: props?.showVatNumber || step === BILLING_STEP },
        events,
        step === SHIPPING_STEP,
        step === BILLING_STEP || step === SHIPPING_STEP,
        false
    );

    const addressGroup = fieldMap.find(({ name }) => name === 'addressGroup');

    if (addressGroup) {
        addressGroup.events = {
            onBlur: onAddressChange,
            onLoad: onAddressChange,
        };
    }

    fieldMap.splice(0, 2);

    fieldMap.map((item) => {
        const { attr: { name } = {} } = item;

        if (name === 'country_id') {
            item.validationRule = {};
            item.addRequiredTag = false;
            item.hidden = true;
        }

        if (name === 'company' && step !== BILLING_STEP) {
            item.attr.placeholder = __('Optional - for shipping only');
        }

        return item;
    });

    if (step === BILLING_STEP) {
        return fieldMap.map((item) => {
            const { attr: { name } = {} } = item;
            if (name === 'vat_id') {
                item.validationRule = {
                    isRequired: true,
                    validateVatIdFormat: true,
                };
                item.validateOn = ['onChange'];
                item.addRequiredTag = true;
                item.events = {
                    onBlur: onVatIdBlur,
                };
            } else if (name === 'company') {
                item.validationRule = {
                    isRequired: true,
                };
                item.validateOn = ['onChange'];
                item.addRequiredTag = true;
                item.label = __('Company name');
            } else if (name === 'firstname' || name === 'lastname' || name === 'telephone') {
                item.validationRule = {};
                item.addRequiredTag = false;
                item.hidden = true;
            }

            return item;
        });
    }

    return fieldMap;
};
